import { Box, useMediaQuery } from '@mui/material'
import { HBox } from 'Components/layout'
import { ChildSpacerH, ChildSpacerHAll } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { CSTooltip } from 'Components/visual/csTooltip'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { ReleaseDate } from 'pages/home/profilePanel/releaseDate'
import { useMemo } from 'react'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import {
  getGamePredictionScore,
  getGamePredictions,
  isGameReleased,
} from 'util/gameLogic'
import { PredictionBadgeMini } from './../visual/scoreBadge/predictionBadgeMini'
import { GameName } from './gameName'
import { GameProfile } from './gameProfile'
import { usePredictions } from 'store/game/predictionsContext'
import { Platforms } from 'pages/home/games/platforms'
import { semanticSurface } from 'styles/semanticSurface'
export const GameCardInfo = ({ game }) => {
  const isMobile = useMediaQuery(query.mobile)

  const { user } = useAuth()
  const userPrediction = useMemo(
    () => getGamePredictionScore(user, game),
    [user, game]
  )

  const { roundGamePredictions } = usePredictions()
  const gamePredictions = useMemo(() => {
    return getGamePredictions(roundGamePredictions, game.id)
  }, [roundGamePredictions, game])

  // Show badge if game is unreleased or is released and the logged in user made a prediction
  let showPredictionBadge = false

  if (
    (user.isAuthenticated && userPrediction > 0) ||
    (!isGameReleased(game) && !game.metacritic)
  ) {
    showPredictionBadge = true
  }

  return (
    <div
      style={{
        padding: '16px',
        backdropFilter: isMobile ? 'none' : 'blur(10px)',
        backgroundColor: semanticSurface.surfaceLayerLow,
        position: 'absolute',
        color: colors.white,
        bottom: 0,
        width: '100%',
        height: isMobile ? '100%' : '40%',
        boxSizing: 'border-box',
      }}
    >
      <Box>
        <div style={{ fontSize: '16px' }}>
          <GameName game={game} />{' '}
        </div>
        {game?.category !== 'Full game' && (
          <SubtleText>
            {game.category === 'Full game' || game.category === null
              ? ''
              : game.category}
          </SubtleText>
        )}
        <Box p={2} />
        <HBox align="space-between">
          <Platforms platforms={game.platforms} enableLabels={false} />
          <ChildSpacerH>
            {gamePredictions?.average > 0 && (
              <CSTooltip text={<>Community average score prediction</>}>
                <span
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingRight: '4px',
                  }}
                >
                  <span style={{ position: 'absolute' }}>
                    <span
                      className="material-symbols-outlined"
                      style={{ opacity: '0.2' }}
                    >
                      Public
                    </span>
                  </span>
                  <span style={{ color: colors.disabled }}>
                    {Math.round(gamePredictions?.average)}
                  </span>
                </span>
              </CSTooltip>
            )}
            {showPredictionBadge && (
              <PredictionBadgeMini score={userPrediction} />
            )}
            {game.metacritic && <ScoreBadge game={game} />}
          </ChildSpacerH>
        </HBox>
        {game?.status > 0 && <SubtleText>Status: {game.status}</SubtleText>}
        <ChildSpacerHAll
          style={{
            whiteSpace: 'nowrap',
            fontSize: '12px',
            position: 'absolute',
            left: '16px',
            bottom: '16px',
          }}
        >
          <GameProfile game={game} />
          <div>{game.tba ? 'No date' : <ReleaseDate game={game} />}</div>
        </ChildSpacerHAll>
      </Box>
    </div>
  )
}
