import { ChildSpacerHAll } from 'Components/layout/ChildSpacer'
import { SurpriseIcon } from './surpriseIcon'

export const GameName = ({ game }) => {
  const gameName =
    game?.name?.length > 79
      ? `${game?.name?.substring(0, 79)}…`
      : game?.name ?? 'Invalid name'

  return (
    <span>
      <ChildSpacerHAll style={{ alignItems: 'center' }}>
        <SurpriseIcon game={game} />
      </ChildSpacerHAll>
      <span
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: '100%',
          display: 'flex',
        }}
      >
        {gameName}
      </span>
    </span>
  )
}
