import { Typography } from '@mui/material'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { CSTooltip } from 'Components/visual/csTooltip'
import { spacing } from 'styles/spacing'

function ProfileHeaderWinnerCrown({ entry }) {
  const crownStyle = {
    transform: 'rotateZ(20deg)',
    position: 'absolute',
    right: 0,
    color: 'gold',
  }

  if (!entry.wins || entry.wins <= 0) {
    return null
  }

  return (
    <CSTooltip id={'profile-crown'} text={`${entry.wins}-time winner`}>
      <span className="material-symbols-outlined" style={crownStyle}>
        trophy
      </span>
    </CSTooltip>
  )
}

export const UserPanelViewHeaderMini = ({ user }) => {
  return (
    <ChildSpacerVAll>
      <Typography
        variant="h3"
        style={{
          textAlign: 'center',
          position: 'relative',
          gap: spacing.md,
          display: 'flex',
        }}
      >
        {user.team.name} <ProfileHeaderWinnerCrown entry={user.team} />
      </Typography>
    </ChildSpacerVAll>
  )
}
