import { Button, Paper, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { CardButton } from './CardButton'
import { GameCardImage } from './gameCardImage'
import { GameCardInfo } from './gameCardInfo'
import { PickDropButton } from './pickDropButton'

const PREFIX = 'GameCard'

const classes = {
  button: `${PREFIX}-button`,
}

const Root = styled('div')({
  [`& .${classes.button}`]: {
    transform: 'scale(1.00)',
    transition: 'all 0.2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  },
})

export const GameCard = ({ game, readonly }) => {
  const isMobile = useMediaQuery(query.mobile)
  const navigate = useNavigate()
  return (
    <Root
      style={{
        position: 'relative',
        display: 'flex',
        flex: 1,
        border: game.selected ? `2px solid ${colors.theme}` : 'none',
        borderRadius: 4,
        margin: game.selected ? '-1 0 0 -1' : '0',
      }}
    >
      <Button
        className={classes.button}
        style={{
          display: 'flex',
          flex: 1,
          padding: 0,
          margin: 0,
          textTransform: 'none',
          textAlign: 'left',
        }}
        onClick={() => navigate('/game/' + game.slug)}
      >
        <Paper
          style={{
            display: 'flex',
            position: 'relative',
            flex: 1,
            height: isMobile ? 140 : 340,
            overflow: 'hidden',
          }}
        >
          <GameCardImage game={game} />
          <GameCardInfo game={game} />

          <span
            style={{
              position: 'absolute',
              left: !isMobile ? 16 : 'initial',
              right: isMobile ? 16 : 0,
              top: 16,
              color: colors.theme,
            }}
          >
            {game.selected && (
              <span className="material-symbols-outlined">check_circle</span>
            )}
          </span>
        </Paper>
      </Button>

      <PickDropButton
        style={{
          position: 'absolute',
          right: 8,
          bottom: 8,
          color: colors.theme,
        }}
        game={game}
        ButtonComponent={CardButton}
        readonly={readonly}
      />
    </Root>
  )
}
