import { Box } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { RenderLeaderboards } from './renderLeaderboards'
import { isBefore } from 'date-fns'
import { useRounds } from 'store/game/roundContext'

export const LeaderboardsContent = () => {
  const location = useLocation()
  const { setSelectedLeaderboardType } = useLeaderboards()

  useEffect(() => {
    const leaderboardTypeParam = new URLSearchParams(location.search).get(
      'type'
    )
    if (
      leaderboardTypeParam === 'fantasy' ||
      leaderboardTypeParam === 'predictor'
    ) {
      setSelectedLeaderboardType(leaderboardTypeParam)
    }
  }, [location, setSelectedLeaderboardType])

  const { mainRounds, selectedRound } = useRounds()

  let pageTitle = useMemo(() => {
    let pageTitle = 'Standings'
    if (isBefore(selectedRound.endDate, mainRounds.current.startDate)) {
      pageTitle = 'Final standings'
    }

    return pageTitle
  }, [mainRounds, selectedRound])

  return (
    <>
      <h1>{pageTitle}</h1>
      <Box p={5} />
      <RenderLeaderboards />
    </>
  )
}
