import { Skeleton } from '@mui/material'
import { CardGrid } from 'Components/game/cardGrid'
import { GamePredictionCard } from 'Components/game/gamePredictionCard'
import { PreviewDrawer } from 'Components/interactive/previewDrawer'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { HLine } from 'Components/visual/HLine'
import { SubtleText } from 'Components/visual/SubtleText'
import { Link } from 'react-router-dom'
import { useGames } from 'store/game/gameContext'
import { usePredictGame } from 'store/game/userPredictGameContext'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'
import { semanticMedia } from 'styles/semanticMedia'

export const UserPredictList = () => {
  const { isLoadingAvailableGames } = useGames()
  const { user } = useAuth()

  const { gamesToPredict, gamesPossibleToPredict } = usePredictGame()

  return (
    <ChildSpacerVAll>
      <PreviewDrawer
        anchor="left"
        previewItemLimit={1}
        ContentComponent={PredictGameGrid}
        isLoading={isLoadingAvailableGames}
        gamesToPredict={gamesToPredict}
        gamesPossibleToPredict={gamesPossibleToPredict}
        user={user}
      />
    </ChildSpacerVAll>
  )
}

function PredictGameGrid({
  user,
  limit,
  isLoading,
  gamesToPredict,
  gamesPossibleToPredict,
}) {
  if (isLoading) {
    return (
      <>
        <HLine label="Predict the review score" />
        <Skeleton
          variant="rectangular"
          height={200}
          sx={{ borderRadius: 2 }}
          style={{ backgroundColor: colors.skeleton }}
        />
      </>
    )
  }

  if (gamesPossibleToPredict.length <= 0) {
    return (
      <>
        <HLine label="Predict the review score" />
        <div style={{ color: semanticMedia.contrastMedium }}>
          There are no suggested games left to predict. There may be more to
          predict under <Link to="/games">Games</Link> if you want to catch them
          all.
        </div>
      </>
    )
  }

  if (gamesToPredict.length <= 0) {
    return (
      <>
        <HLine label="Predict the review score" />
        <div style={{ color: semanticMedia.contrastMedium }}>
          Way to go! You have predicted review scores for every suggested game.
          There may be more to predict under <Link to="/games">Games</Link> if
          you want to catch them all.
        </div>
      </>
    )
  }

  return (
    <>
      <HLine label="What's your prediction?" />
      <div style={{ color: semanticMedia.contrastMedium }}>
        Enter which exact review score you think the following game will
        receive. The closer you are, the more points you get for the predictions
        mode!
      </div>
      <CardGrid
        limit={limit}
        list={gamesToPredict}
        isLoading={isLoading}
        Component={GamePredictionCard}
        readonly={true}
        emptyText="Way to go! You have predicted review scores for every suggested game. There may be more to predict under Games if you want to catch them all."
      />
      {user.isAuthenticated && gamesToPredict.length > 0 && (
        <SubtleText>
          Predict the review score for {gamesToPredict.length} more{' '}
          {gamesToPredict.length > 1 ? 'games' : 'game'} to have the maximum
          chance to win.
        </SubtleText>
      )}
    </>
  )
}
