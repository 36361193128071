import { Box, Button, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import LogoImg from './logo.svg'

export const Logo = () => {
  const navigate = useNavigate()
  const isSmall = !useMediaQuery(query.desktopXLUp)

  return (
    <div style={{ display: 'flex' }}>
      <Button
        onClick={() => navigate('/')}
        style={{
          padding: '16px 16px 16px 16px',
          margin: '-8px 0 0 -16px',
        }}
      >
        <Box display="inline-block" style={{ position: 'relative' }}>
          <img
            src={LogoImg}
            style={{
              maxWidth: '100%',
              maxHeight: isSmall ? '40px' : '64px',
              marginBottom: '-10px',
            }}
            alt="logo"
          />
          <span
            style={{
              position: 'absolute',
              right: '-18px',
              transform: 'rotateZ(27deg)',
              color: colors.white,
              fontSize: isSmall ? '9px' : '18px',
            }}
            title="One could almost say extremely alpha but this is how Google does it so it's the law..."
          >
            BETA
          </span>
        </Box>
      </Button>
    </div>
  )
}
