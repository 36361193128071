import { Footer } from 'Components/panes/footer'
import { Outlet, useLocation } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { query } from 'styles/mediaQueries'
import { spacing } from 'styles/spacing'
import { Cols } from '../../Components/layout/cols'
import MobileNav from '../../Components/layout/mobileNav'
import { Rows } from '../../Components/layout/rows'
import { MobileHeader } from './header/mobileHeader'
import { NoMatch } from './noMatch'
import { ProfilePanel } from './profilePanel/profilePanel'

export const HomePage = () => {
  const location = useLocation()

  return (
    <>
      <Breakpoint customQuery={query.mobile} style={{ height: '100%' }}>
        <Rows set="1fr auto" style={{ height: '100%', marginBottom: '64px' }}>
          <div>
            <MobileHeader />
            <div
              style={{
                margin: `${spacing.pageMobile} ${spacing.pageMobile} 48px ${spacing.pageMobile}`,
              }}
            >
              {location.pathname !== '/admin' ? <Outlet /> : <NoMatch />}
            </div>
          </div>
          <div
            style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 1 }}
          >
            <MobileNav />
          </div>
        </Rows>
      </Breakpoint>
      <Breakpoint customQuery={query.mobileToTablet} style={{ height: '100%' }}>
        <Rows set="1fr auto" style={{ height: '100%', marginBottom: '48px' }}>
          <div>
            <MobileHeader />
            <Cols set="auto">
              <div>
                <div
                  style={{
                    margin: `${spacing.pageTablet} ${spacing.pageTablet} ${spacing.pageTablet} ${spacing.pageTablet}`,
                  }}
                >
                  {location.pathname !== '/admin' ? <Outlet /> : <NoMatch />}
                </div>
              </div>
            </Cols>
          </div>
          <div
            style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 1 }}
          >
            <MobileNav />
          </div>
        </Rows>
      </Breakpoint>
      <Breakpoint customQuery={query.tabletToDesktopXL}>
        <Rows set="1fr auto">
          <Cols set="400px auto">
            <ProfilePanel />
            <div>
              <div
                style={{
                  margin: `${spacing.pageTablet} ${spacing.pageTablet} 0 ${spacing.pageTablet}`,
                }}
              >
                {location.pathname !== '/admin' ? <Outlet /> : <NoMatch />}
              </div>
            </div>
          </Cols>
          <Footer />
        </Rows>
      </Breakpoint>
      <Breakpoint customQuery={query.desktopXLUp}>
        <Rows set="1fr auto">
          <Cols set="400px auto">
            <ProfilePanel />
            <div>
              <div
                style={{
                  margin: `${spacing.pageTablet} ${spacing.pageDesktop} 0 ${spacing.pageDesktop}`,
                }}
              >
                {location.pathname !== '/admin' ? <Outlet /> : <NoMatch />}
              </div>
            </div>
          </Cols>
          <Footer />
        </Rows>
      </Breakpoint>
    </>
  )
}
