import { CardGrid } from 'Components/game/cardGrid'
import { GameCard } from 'Components/game/gameCard'
import { PreviewDrawer } from 'Components/interactive/previewDrawer'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { HLine } from 'Components/visual/HLine'
import { useGames } from 'store/game/gameContext'
import { semanticMedia } from 'styles/semanticMedia'

const listTitle = 'New reviews'

export const ReviewedList = () => {
  return <ReviewedListRenderer />
}

const ReviewedListRenderer = () => {
  const { isLoadingAvailableGames, reviewedList } = useGames()

  if (!isLoadingAvailableGames && reviewedList.length <= 0) {
    return (
      <>
        <HLine label={listTitle} />
        <p>Recent reviews within the past week show up here.</p>
      </>
    )
  }

  return (
    <ChildSpacerVAll>
      <PreviewDrawer
        anchor="left"
        ContentComponent={DrawerGameGrid}
        isLoading={isLoadingAvailableGames}
        reviewedList={reviewedList}
      />
    </ChildSpacerVAll>
  )
}

function DrawerGameGrid({ limit, isLoading, reviewedList, onButtonClick }) {
  return (
    <>
      <HLine label={listTitle} />
      <p style={{ color: semanticMedia.contrastMedium }}>
        New reviews from the last week, we've highlighted games you picked in
        green.
      </p>
      <CardGrid
        limit={limit}
        list={reviewedList}
        isLoading={isLoading}
        Component={GameCard}
        readonly={true}
        emptyText="No games are reviewed on metacritic yet for this round."
      />
    </>
  )
}
