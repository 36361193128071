import { Box, Button } from '@mui/material'
import { semanticMedia } from 'styles/semanticMedia'

export const HLine = ({
  label,
  icon,
  buttonLabel,
  onButtonClick,
  children,
}) => {
  return (
    <h3
      style={{
        color: semanticMedia.accentTheme,
        padding: '0 0 8px 0',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: '32px',
        lineHeight: '32px',
      }}
    >
      {icon && (
        <span
          className="material-symbols-outlined"
          style={{ verticalAlign: 'text-top', marginRight: '8px' }}
        >
          {icon}
        </span>
      )}{' '}
      {label || children}
      <Box flex={1}></Box>
      {buttonLabel && onButtonClick && (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={onButtonClick}
        >
          {buttonLabel}
        </Button>
      )}
    </h3>
  )
}
